import React, { useEffect } from "react";

import Seo from "../components/seo/index";

import Layout from "../layout/index";
import HomePage from "./home";
import { lStorage } from "../components/utils/storage";

// import Bannerheartfulness from "../components/home/banner-heartfulness";
// markup
const SpecialDonation = () => {
  useEffect(() =>{
    const isSpecialDonation =  lStorage.get("specialDonation");

    if(isSpecialDonation === "NO"){
    lStorage.remove("list");
    lStorage.remove("appliedCouponCode");
    }
    lStorage.set("specialDonation", "YES");
  },[])

  return (
    <div>
      <Seo title="Green Heartfulness Run 2024 - Special Discount" />
      <Layout isSticky>
        <HomePage />
      </Layout>
    </div>
  );
};

export default SpecialDonation;
